import TopInformation from './TopInformation.jsx';
import Introduction from './Introduction.jsx';
import Event from './Event/Event.js'
import ScrollToTopOnMount from '../MainContents/AutoScrollTop';
import row from '../../img/row.jpg';
import Image from '../../img/image.jpg';
import styled from 'styled-components';
import { device } from '../MainContents/Style/StyleDevice'
import MediaQuery from "react-responsive";
import PublicDeadline from './PublicDeadline.js'

const MainNews = styled.div`
padding-top:50px;
background: #fbfbfd;
`;
const TestLink = styled.h4`
text-align: center;
margin-top: 30px;
`;

const MainNewsTitle = styled.div`
// display: none;
color: #00ad80;
font-size: 25px;
@media ${device.laptop}{
    font-size: 17px;
 }
`;

const Title = styled.h2`
    color: #009d80;
    text-align: center;
`;
const SubBack = styled.div`
    background-color: #f5f5f5;

`;
const SubTextLi = styled.li`
    margin-top: 3px;
    list-style-type: none; 
    position: relative; 
    padding-left: 0.6em;
    &:before {
        border-radius: 50%; /*丸くする*/
        width: 5px; /*点の幅*/
        height: 5px; /*点の高さ*/
        display: block;
        position: absolute; /*絶対配置*/
        left: 0; /*点の位置*/
        top: 0.6em; /*点の位置*/
        content: "";
        background: #009d80; /*点の色*/
    }
`;


const MainImg = styled.img`
width: 100%;
// height: 50vw;
// object-fit: cover;
@media ${device.laptop}{
    // filter: brightness(0.97) invert(0);
 }

`;

const ImgIntroduction = styled.div`
    position:absolute;
    top:3%;
    padding:10px;
    margin-left: 20px;
    font-weight:bold;
    letter-spacing: 2px;
    color: #000000;
    border-radius: 3px;
    font-size: 26px;
`;
const ImgIntroductionSp = styled.div`
    position:absolute;
    top:5%;
    padding:10px;
    font-weight:bold;
    letter-spacing: 2px;
    color: #000000;
    background: rgba(251,251,253,0.3);
    border-radius: 3px;
    font-size: 15px;
`;

const Text = styled.p`
    border-bottom: 1px solid #fbfbfd;
`;
const TextSecound = styled.p`
    border-bottom: 1px solid #fbfbfd;
    width: 718px;
`;

const PartHall = styled.div`
    position: absolute;
    top: 20%;
    padding: 10px 10px 0px 10px;
    margin-left: 30px;
    font-weight: bold;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    border-radius: 3px;
    font-size: 26px;
    color: #232323;
    background: #fff;
    border-left: solid 8px #00ad80;
`;
const PartHallPs = styled.div`
position: absolute;
    top: 86%;
    font-weight: bold;
    letter-spacing: 2px;
    color: #000000;
    background: rgba(251,251,253,0.3);
    border-radius: 3px;
    font-size: 16px;
    letter-spacing: 2px;
    border-radius: 3px;
    font-size: 12px;
    background: #fff;
    border-left: solid 8px #00ad80;
    padding: 10px 10px 0px 10px;
    margin-left: 5px;
`;

const IntroductionCenter = styled.div`
width: 50%;
@media ${device.laptop}{
    width: 95%;
 }
margin: 10px auto;
border: 1px solid #009d80;
`;
const UlDelete = styled.ul`
    list-style: none;
`;

const LiColor = styled.li`
    color: #009d80;
    font-size:20px;
    font-weight: bold;
`;


// 採点模試訂正　を12月22日まで表示する
// let Correction = () => {
//     var now = new Date();
//     var end = new Date('2023/02/10 12:00:00');
//     if (now > end) {
//         // return  <TestLink><a href="https://tosajuku.info/tsjk_webpage/reservation/Listening/index.php" style={{ color: '#00ad80' }}>第６回　リスニングの音源はこちら</a></TestLink>
//         return <></>
//     } else {
//         return <></>
//     }
// }

// トップページ
const Main = () => {
    const SvgUrl = 'http://www.w3.org/2000/svg';
    const SvgD = 'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z';


    return (
        <>
            {/* トップバナー */}
            <ScrollToTopOnMount />
            <div style={{ marginTop: '60px' }}></div>
            <div className="card border-0">
                {/* <Correction /> */}
                {/* <div className="mt-4 text-center" style={{width: '90%',margin: 'auto'}}>
                    <p></p>
                    <h3>統一 第６回 解答の訂正による 個人表の訂正について</h3>
                    <p>以下の二教科につきまして、ご確認をお願いいたします。</p>
                    <hr />
                    <p>数学　<span className="pt-1 pl-2 pb-1 pr-2" style={{border:'1px solid #000000'}}>1</span> (7) ②</p>
                    <p>問題文にあいまいさがありましたので、解) 25と26を正解とします。</p>
                    <p>理科　<span className="pt-1 pl-2 pb-1 pr-2" style={{border:'1px solid #000000'}}>5</span> 3.</p>
                    <p>解答が誤っていましたので、解) 1000Pa に訂正します。</p>
                    <hr />
                    <p>誠に申し訳ありませんでした。採点の誤りについては、個人表の訂正をさせていただきます。</p>
                    <p>↓訂正ページからご連絡をお願いいたします。</p>
                    <h4><a href="https://tosajuku.info/tsjk_webpage/reservation/receptionReservation/correction.html">訂正ページはこちら</a></h4>
                    <h6>2/10（金）締め切りとさせていただきます。</h6>
                </div> */}
                
                {/* <div className='row text-center mt-3'>
                    <div className='col-6'><a href="https://toitumosi.jp/img/kai6.pdf" style={{color: 'rgb(0, 173, 128)'}}>第６回　統一模試&ensp;偏差値判定基準&ensp;(PDF)</a></div>
                    <div className='col-6'><a href="https://toitumosi.jp/img/2021_distribution_6.pdf" style={{color: 'rgb(0, 173, 128)'}} >第６回　統一摸試&ensp;学校別分布表&ensp;(PDF)</a></div>
                </div> */}
                
           
                <MainNews className=" card-body text-center ">
                   
                   {/* 公開期日　申込情報を掲載 */}
                   {/*<PublicDeadline />*/}

                    <MainNewsTitle className="container">
                    {/* styled noneで非表示扱いにしている */}
                        {/* <div className="row" style={{ marginBottom: '15px' }}>

                            <div className="col-sm mt-3">
                                <a href="#process" style={{ color: '#00ad80' }}>
                                    初めての方はこちら&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD} /></svg>
                                </a>
                            </div>
                            <div className="col-sm mt-3">
                                <a href="https://tosajuku.info/tsjk_webpage/reservation/receptionReservation/index.html?theWhatTimes=3" style={{ color: '#00ad80' }}>
                                    &ensp;&ensp;予約はこちら&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD} /></svg>
                                </a>
                            </div>
                            <div className="col-sm mt-3">
                                <a href="https://tosajuku.info/tsjk_webpage/reservation/alteration/" style={{ color: '#00ad80' }}>
                                    &ensp;&ensp; キャンセルはこちら　<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD} /></svg>
                                </a>
                            </div>
                        </div> */}
                    </MainNewsTitle>
                </MainNews>
                {/* 紹介の記述が多くなってきたためリファクタリングが必要 別インポートが必要になるのかなと思う*/}
                {/* <IntroductionCenter className=''>
                <UlDelete>
                    <LiColor className='pt-2'>当日は回収物の中に、住所・氏名・電話番号等の個人情報の記入が必須になります。</LiColor>
                    <LiColor>会場付近は大変混み合います</LiColor>
                    <LiColor>会場へのお車の乗り入れはお断りします。</LiColor>
                </UlDelete> 
                </IntroductionCenter> */}


                
            </div>

            {/* スマホ */}
            <MediaQuery query="(max-width: 768px)">
                <div class="card  text-white border-0">
                    <MainImg src={row} class="card-img font-weight-bold" alt="..." />
                    <ImgIntroductionSp class="card-img-overlay">
                        {/* <SpTitle class="card-title">統一模試を初めてご利用になる方へ</SpTitle> */}
                        <p class="card-text">高知県公立高校の入試形式に沿った問題で合格判定を出す県内最大規模の公開模試です。</p>
                        <p class="card-text" style={{ marginTop: '-10px' }}>例年総数10000人以上の中学3年生が受験しております。</p>
                    </ImgIntroductionSp>
                    <PartHallPs>
                        <p style={{ color: "#00ad80" }}><a href="#process" style={{ color: '#00ad80' }}>一部の会場では予約が必要になります。</a>&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD} /></svg></p>
                    </PartHallPs>
                </div>
            </MediaQuery>

            {/* パソコン */}
            <MediaQuery query="(min-width: 769px)">
                <div class="card bg-dark text-white border-0">
                    <MainImg src={Image} class="card-img font-weight-bold" alt="..." />
                    <ImgIntroduction class="card-img-overlay">
                        {/* <SpTitle class="card-title">統一模試を初めてご利用になる方へ</SpTitle> */}
                        <Text class="card-text">高知県公立高校の入試形式に沿った問題で合格判定を出す県内最大規模の公開模試です。</Text>
                        <TextSecound class="card-text">例年総数10000人以上の中学3年生が受験しております。</TextSecound>
                    </ImgIntroduction>

                    <PartHall>
                        <p style={{ color: "#00ad80" }}><a href="#process" style={{ color: '#00ad80' }}>一部の会場では予約が必要になります。</a>&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD} /></svg></p>
                    </PartHall>
                </div>
            </MediaQuery>
            {/* お知らせ */}
            <TopInformation />

            {/* 受付手順 */}
            <Event />

            {/* 受験で配られるもの */}
            <SubBack className="card center  border-0" >
                <div className="card-body ">
                    <Title className="card-title">ー&ensp;統一模試活用法&ensp;ー</Title>
                    <h5>当日受験会場で配られるもの</h5>
                    <ul>
                        <SubTextLi>模試の解答・解説   :答案を返却されたら、すぐに見直しをして弱点を克服してください。</SubTextLi>
                        <SubTextLi>高校入試情報等     ：受験生に役立つ入試情報をお知らせします。</SubTextLi>
                    </ul>
                    <h5>後日郵送等で送られるもの</h5>
                    <ul>
                        <SubTextLi>偏差値による判定基準   ：過去の入試データと比較して、学校ごとの合格可能性を偏差値で判定。</SubTextLi>
                        <SubTextLi>志望校別得点階層分布表：志望校別の受験者の得点分布が一目でわかります。</SubTextLi>
                        <SubTextLi>個人別成績表   ：五教科の合計点で志望校の判定が表示されています。</SubTextLi>
                    </ul>
                </div>
            </SubBack>


            {/* コンテンツ紹介 */}
            <Introduction />
        </>
    );
}

export default Main;
