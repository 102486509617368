import React from "react";
import MediaQuery from "react-responsive";

const scheduleItems = [
    {
        name: "第1回",
        startTime: new Date('2024-08-26T12:00:00'),
        endTime: new Date('2024-09-23T08:59:59'),
    },
    {
        name: "第2回",
        startTime: new Date('2024-09-24T11:59:59'),
        endTime: new Date('2024-10-20T08:59:59'),
    },
    {
        name: "第3回",
        startTime: new Date('2024-10-21T11:59:59'),
        endTime: new Date('2024-11-23T08:59:59'),
    },
    {
        name: "第4回",
        startTime: new Date('2024-11-24T11:59:59'),
        endTime: new Date('2024-12-15T08:59:59'),
    },
    {
        name: "第5回",
        startTime: new Date('2024-12-16T11:59:59'),
        endTime: new Date('2025-01-19T08:59:59'),
    }
    // {
    //     name: "第6回",
    //     startTime: new Date('2025-01-19T11:59:59'),
    //     endTime: new Date('2025-01-28T08:59:59'),
    // }
];


const getCurrentSchedule = () => {
    const now = new Date();
    return scheduleItems.find(item => now >= item.startTime && now <= item.endTime);
};

const formatMonthDay = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    const weekday = date.toLocaleString("default", { weekday: "short" });
    return `${month}${day}日(${weekday})`;
};

const Public = () => {
    const currentSchedule = getCurrentSchedule();
    
    if (currentSchedule) {
        const { name, startTime, endTime } = currentSchedule;
        const now = new Date();
        if (now < startTime) {
            return <h1 className="font-weight-bold">準備中です</h1>;
        }

        return (
            <div>
                <h1 className="font-weight-bold">
                    {name} {formatMonthDay(endTime)} <h4 className="mt-1">予約受付中</h4>
                </h1>
                <div style={{ marginTop: "30px" }}>
                    <p style={{ color: "red", fontSize: "1.5em", fontWeight: "bold" }}>注意事項</p>
                    <p style={{ fontSize: "1.5em", fontWeight: "bold" }}>各回、公開会場案内に載っている会場は</p>
                    <p style={{ fontSize: "1.5em", fontWeight: "bold" }}>受付開始　8：30　模試開始　9：00　になります</p>
                    <p style={{ color: "red", fontSize: "1.5em", fontWeight: "bold" }}>但し、土佐塾イオンモール高知教室のみ、各回</p>
                    <p style={{ color: "red", fontSize: "1.5em", fontWeight: "bold" }}>受付開始　12：30　模試開始　13：00になります</p>
                    <p style={{ fontSize: "1.5em", fontWeight: "bold" }}>お間違えの無いようにお越しください</p>
                    
                    <p style={{ fontSize: "1.5em", fontWeight: "bold" }}><span style={{color: "red"}}>New</span>　旭・横内会場：高知開成専門学校　新設</p>
                    <p style={{ fontSize: "1.5em", fontWeight: "bold" }}><span style={{color: "red"}}>New</span>　いの・高岡地区：高知リハビリテーション専門職大学　新設</p>
                </div>
            </div>
        );
    }

    // currentSchedule が undefined の場合に返す内容を追加
    return <h1 className="font-weight-bold">現在、準備中です。</h1>;
};

const PublicSp = () => {
    const currentSchedule = getCurrentSchedule();

    if (currentSchedule) {
        const { name, startTime, endTime } = currentSchedule;
        const now = new Date();
        if (now < startTime) {
            return <h1 className="font-weight-bold">準備中です</h1>;
        }

        return (
            <div>
                <h4 className="font-weight-bold">
                    {name} {formatMonthDay(endTime)} <h6 className="mt-1">予約受付中</h6>
                </h4>
                <div style={{ marginTop: "30px" }}>
                    <p style={{ color: "red", fontSize: "1.2em", fontWeight: "bold" }}>注意事項</p>
                    <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>各回、公開会場案内に載っている会場は</p>
                    <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>受付開始　8：30　模試開始　9：00　になります</p>
                    <p style={{ color: "red", fontSize: "1.2em", fontWeight: "bold" }}>但し、土佐塾イオンモール高知教室のみ、各回</p>
                    <p style={{ color: "red", fontSize: "1.2em", fontWeight: "bold" }}>受付開始　12：30　模試開始　13：00になります</p>
                    <p style={{ color: "red", fontSize: "1.2em", fontWeight: "bold" }}>お間違えの無いようにお越しください</p>
                </div>
            </div>
        );
    }

    // currentSchedule が undefined の場合に返す内容を追加
    return <h4 className="font-weight-bold">現在、準備中です。</h4>;
};


const PublicDeadline = () => {
    return (
        <>
            <MediaQuery query="(max-width: 768px)">
                <PublicSp />
            </MediaQuery>

            <MediaQuery query="(min-width: 769px)">
                <Public />
            </MediaQuery>
        </>
    );
};

export default PublicDeadline;